$primary: blue;
$secondary: red;
$third: grey;

@import "mixins";
@import "variables";
@import "animate";
@import "bootstrap/bootstrap";


html {
  box-sizing: border-box !important;
}

*, *:before, *:after {
  box-sizing: inherit !important;
} 

body {
  /*background-image: url('images/test-background.jpg');
  background-position: top center;
  background-size: cover;*/
  /*background-image: url('../images/main-background.png');*/
  background-repeat: repeat;
  background-color: white;
  font-family: 'Raleway', san-serif;
  font-size: 16px;
  line-height: 1.1;
  background-color: cornsilk;  //f5fffa
}

h1 {font-family: 'Tinos', serif; font-weight: 300; font-size: 3em !important; color: black; margin-top: 20px !important; margin-bottom: 30px !important;  }
h2 {font-family: 'Tinos', serif; font-weight: 300; font-size: 1.8em; color: black;}
h3 {font-family: 'Tinos', serif; font-weight: 300; font-size: 1.6em; color: black; margin-bottom: 15px;}
h4 {font-family: 'Raleway', san-serif; font-weight: bold; font-size: 1.3em; color: black; margin-bottom: 15px;}
h5 {font-family: 'Raleway', san-serif; font-size: 1.1em; font-weight: bold; color: black;  margin-bottom: 15px;}

.embed-container { 
  position: relative; 
  height: 0; 
  overflow: hidden; 
  height: 50vh;
  position:relative; 
  @include breakpoint($md) {
    /*margin-top: 100px;*/
  }
 } 

.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; max-height: 50vh; 
}

.responsive-tabs .responsive-tabs__heading--active, .responsive-tabs .responsive-tabs__heading--active:hover {
    background: black !important;
    color: #fff !important;
}

.fb_iframe_widget, .fb_iframe_widget iframe, .fb_iframe_widget span, .fb_iframe_widget div  {
    position: absolute;
    min-width: 100%;
}

.overlay {
  width:100%;
  min-height:50vh;  
  position:absolute; 
  top:0;
}

.widget-footer {
  h3 {display: none;}

  a {
    color: white;
  }

}

ul.instagram-pics {
  display: inline-block;
  margin: 0px;
  padding: 0px;

  li {
    width: 25%;
    margin: 0px;
    display: block;
    float: left;
    padding: 5px;

    img {
      width: 100%;
    }
  }

}

.responsive-tabs {
  padding-bottom: 30px;
}

.responsive-tabs__list__item {
  @include breakpoint($sm) {
    font-size: 12px !important;
  }
  @include breakpoint($md) {
    font-size: 18px !important;
  }
}

.awards h4 {
  text-align: center;
  margin-bottom: 5px;
  margin-bottom: 5px;
}

.more-awards-left {
  text-align: right;
}

.more-awards-right {
  text-align: left;
}

p {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
}

img {
  max-width: 100%; 
  height: auto;
  @include breakpoint($xs) {
    margin: 0px auto;
    display: block;
}
}

/*.solid-back {
  background: rgba(0,0,0, 1) !important;
}*/

  a.availability-button {

    display: block;
    float: right;
    /* margin: 0px auto; */
    padding: 12px 15px;
    background-color: #3F9645;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    border: 0px;
    /* float: left; */
    /* top: 50%; */
    /* position: absolute; */
    margin-top: 10px;
    letter-spacing: 5px;
    border: 1px solid #3F9645;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background-color: white;
      color: #3F9645;
    }

    @include breakpoint($xs) {
      font-size: 60%;
      padding: 8px 8px;
      display: block;
      margin: 10px auto;
      float: none;
      text-align: center;
    }

  }

.page {
  .header {
  background: rgba(0,0,0, 1);
  position: relative;
  }
}

.header {
  display: inherit;
  position: absolute;
  width: 100%;
  /*height: 120px;*/ 
  background: rgba(0,0,0, .5);
  z-index: 20;
  top: 0px;

  #navbar {
    margin-top: 25px;

    a {
      color: white;
      transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
    }
    a:hover {
      color: #3F9645;
    }

  }

  .logo  {
    display: inline-block;
    height: 100px;

    img {
      display: inline-block;
      max-height: 100%;

      @include breakpoint($xs) {
        max-height: 100px;
      }
    }
  }

  .navbar-brand {
  padding: 8px;

    @include breakpoint($xs) {
      padding: 8px;
      float: left;
      height: auto;
    }
  }

    @include breakpoint($xs) {
      display: inline-block;
      position: relative;
      background-color: black;
    }

}



.footer {
  margin-top: 50px;
  background: rgba(0,0,0, 1);
  padding: 90px 0px;

  .logo {
    margin: 0px; float: left;
  }

  p {
    color: white;
  }

  .availability-button {
    display: block;
        /* margin: 0px auto; */
        padding: 12px 15px;
        background-color: #3F9645;
        color: white;
        border-radius: 5px;
        text-transform: uppercase;
        border: 0px;
        /* float: left; */
        /* top: 50%; */
        /* position: absolute; */
        margin-top: 10px;
        letter-spacing: 5px;

        @include breakpoint($xs) {
          display: block;
          margin: 10px auto;
          float: none;
          text-align: center;
        }
  }
}

a.button-gallery {
display: block;
width: 100%;
margin: 0px auto;
padding: 5px 20px;
border-radius: 2px;
background-color: black;
color: white;
border: 0pc;
text-align: center;

&:hover {
background-color: gray;
color: white;
}

}


#myCarousel .carousel-inner .item {
max-height: 80vh;

}

#myCarousel .carousel-inner .item img {

}

.gallery-item {
    float: left;
    margin: 0px !important;
    text-align: center;
    width: 33.33333% !important;

    img {
      border: 1px black solid!important;
    }
}

.tripadvisor {
display: block;
max-height: 250px;
margin: 30px auto 10px;
}

.fivepadding {padding: 5px;}
.fiveVpadding {padding-top: 5px; padding-bottom: 5px;}
.fiveHpadding {padding: 0px 5px;}

.tenpadding {padding: 10px;}
.tenVpadding {padding: 10px 0px;}
.tenHpadding {padding: 0px 10px;}

.twentypadding {padding: 20px;}
.twentyVpadding {padding: 20px 0px;}
.twentyHpadding {padding: 0px 20px;}

.nopadding {
padding: 0 !important;
}

.fivemargin {margin: 5px;}
.fiveVmargin {margin: 5px 0px;}
.fiveHmargin {margin: 0px 5px;}

.tenmargin {margin: 10px;}
.tenVmargin {margin: 10px 0px;}
.tenHmargin {margin: 0px 10px;}

.twentymargin {margin: 20px;}
.twentyVmargin {margin: 20px 0px;}
.twentyHmargin {margin: 0px 20px;}

.nomargin {
margin: 0 !important;
}


#afterbig1 {
width: 100%;
min-width: 100%;
}

.general_table p {
  margin: inherit !important;
  padding: 10px !important;
}

.thickbox-previewbigcalendar1 {
font-size: 120%;
}

.eo-events {
line-height: 1.4;
}
.home-calendar-title .fa {
margin-right: 10px;
}

.footer-box a {
color: lavenderblush;
}



.readmore {float: left; background-color: #3f9645; padding: 10px; width: auto; border: 0px; margin-bottom: 10px; color: white; text-transform: uppercase;}

.readmore:hover {color: white; text-decoration: none;}

.carousel-caption H3 {

color: black;
font-weight: 500;
text-shadow: 0x 0px 0px black !important;
text-transform: uppercase;
background-color: rgba(250,250,250, .5);
padding: 10px;
}

.featured-img {
border-radius: 5px;
}

.featured-title {
text-align: center;
text-transform: uppercase;
padding: 10px 0px;
font-weight: bold;
}


/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
clip: rect(1px, 1px, 1px, 1px);
position: absolute !important;
height: 1px;
width: 1px;
overflow: hidden;
}

.screen-reader-text:focus {
background-color: #f1f1f1;
border-radius: 3px;
box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
clip: auto !important;
color: #21759b;
display: block;
font-size: 14px;
font-size: 0.875rem;
font-weight: bold;
height: auto;
left: 5px;
line-height: normal;
padding: 15px 23px 14px;
text-decoration: none;
top: 5px;
width: auto;
z-index: 100000; /* Above WP toolbar. */
}



/*
h1 {font-weight: 300; font-size: 2.5em; color: #3f9645; }
h2 {font-weight: 300; font-size: 1.8em; color: #f6861f;}
h3 {font-weight: bold; font-size: 1.6em; color: #3f9645; margin-bottom: 15px;}
h4 {font-weight: 300; font-size: 1.2em; color: #3f9645; margin-bottom: 15px;}
h5 {font-size: 1.1em; color: black;  margin-bottom: 15px;}
*/

/* apply a natural box layout model to all elements, but allowing components to change */



/*

#sidebar h2 {

background-color: gray;
width: 100%;
height: auto;
padding: 10px 20px;
margin: 30px auto 10px;
color: white;
border-radius: 5px;
border: 1px solid #76a362;
line-height: 1.4;
text-align: center;

}

#sidebar h3 {

background-color: gray;
width: 100%;
height: auto;
padding: 10px 20px;
margin: 30px auto 10px;
color: white;
border-radius: 5px;
border: 1px solid #76a362;
line-height: 1.4;
text-align: center;

}



.purple-button {
  display:block;
  margin: 0px auto;
  padding: 5px;
  background-color: #9812d8;
  color: white;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
}

.purple-button:hover {
  text-decoration: none;
  background-color: #c15ef1;
  color: white;
}


  .slider-fixedBackground {
  background-image: url('../images/footer-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

/*.dropdown {
    margin: 10px 5px;
  border: 2px solid black !important;
 display: block;
  width: 100%;

  /*padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

 .dropdown button {border:  none; width: 100%; text-align: left;} */

 .btn .caret {
  margin-left: 0;
  float: right;
  margin-top: 8px;
}



.btn-default {
  text-shadow: 0 1px 0 #fff;
  /* background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%); */
  /*background-image: -o-linear-gradient(top,#fff 0,#e0e0e0 100%);*/
  /* background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e0e0e0)); */
  /* background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%); */
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);*/
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.btn-default {
  /*text-shadow: 0 -1px 0 rgba(0,0,0,.2);*/
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);
}

.btn-default:focus, .btn-default:hover {
  background-color: transparent;
  /* background-position: 0 -15px; */
}

.gallery-icon {
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid gray;
  border-radius: 5px;
   transition: all .25s ease-in-out;
-moz-transition: all .25s ease-in-out;
-webkit-transition: all .25s ease-in-out;
}

.responsive-tabs {

  @include breakpoint($md) {

    ul.responsive-tabs__list {
    /*line-height: 18px;*/
    margin: 20px 0 0 1px;
    padding: 0;
    display: table-row;
    width: 100%; 
    table-layout: fixed;
    }

    .responsive-tabs__list__item {
      font-size: 1.3vw !important;
        display: table-cell;
    }
    .responsive-tabs__list__item--active {
      font-weight: bold;
      font-size: 1.5vw !important;
    }
}

}

.book-button {
  display: inline-block;
  /* margin: 0px auto; */
  padding: 12px 20px;
  background-color: #3F9645;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  border: 0px;
  float: right;
  top: 50%;
  /* position: absolute; */
  margin-top: 30px;
  letter-spacing: 10px;

  &:hover {
    color: white;
    text-decoration: none;
    cursor: pointer; 
  }

  @include breakpoint($xs) {
    font-size: 60%;
    margin: 15px auto;
    float: none;
    letter-spacing: 3px;
    display: block;
  }
}

.book-button-bar {
  display: inline-block;
  /* margin: 0px auto; */
  padding: 12px 20px;
  background-color: #3F9645;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  border: 0px;
  top: 50%;
  /* position: absolute; */
  margin-top: 10px;
    letter-spacing: 10px;
    @include breakpoint($xs) {
      margin: 10px auto;
      float: none;
      letter-spacing: 5px;
    }

}

.bar-dark {
  margin: 60px 0px;
  background-color: black;
  color: white;
  border-top: 0px solid green;
  border-bottom: 1px solid green;
}

.bar-dark h3 {
  color: white;
}

.bar-dark {
  text-align: center;
}


.green-button:hover {
text-decoration: none;
background-color: gray;
color: white;
}

.orange-button {
display: inline-block;
margin: 0px auto;
padding: 7px;
background-color: #f6861f;
color: white;
border-radius: 5px;
text-transform: uppercase;
}

.orange-button:hover {
text-decoration: none;
background-color: gray;
color: white;
}

.vertical-scroller {

padding: 15px;


}

.col-md-2 img, .col-md-4 img, .col-md-8 img, .col-md-12 img  {max-width: 100%; height: auto;}

.fivepadding {padding: 5px;}
.fiveVpadding {padding-top: 5px; padding-bottom: 5px;}
.fiveHpadding {padding: 0px 5px;}

.tenpadding {padding: 10px;}
.tenVpadding {padding: 10px 0px;}
.tenHpadding {padding: 0px 10px;}

.twentypadding {padding: 20px;}
.twentyVpadding {padding: 20px 0px;}
.twentyHpadding {padding: 0px 20px;}

.nopadding {
padding: 0 !important;
}

.fivemargin {margin: 5px;}
.fiveVmargin {margin: 5px 0px;}
.fiveHmargin {margin: 0px 5px;}

.tenmargin {margin: 10px;}
.tenVmargin {margin: 10px 0px;}
.tenHmargin {margin: 0px 10px;}

.twentymargin {margin: 20px;}
.twentyVmargin {margin: 20px 0px;}
.twentyHmargin {margin: 0px 20px;}

.nomargin {
margin: 0 !important;
}

.topheader {
background-color: white;
z-index: 5000;
min-height: 100px;
}

.phone-number {color: #9812d8; font-family: 'Quicksand', sans-serif; font-size: 1.5em; font-weight: 700;}

.tag-line {font-family: 'Quicksand', sans-serif; font-size: 1.1em; font-weight: 400;}

.nav-container {
background-color: #225655; color: white;  font-size: 1em;
/*background-image: url('../images/menu-background.jpg');*/
  background-position: top center;
  background-size: cover;
  height: 47px;
}
.nav-container a {color: white;}

.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
background-color: transparent !important;
border: none;
}

.nav-tabs>li>a {
margin-right: 2px;
line-height: 1.42857143;
border: 0px solid transparent;
border-radius: none;
padding: 8px 5px !important;
}

.nav>li>a:hover, .nav>li>a:focus {
  text-decoration: none;
  background-color: transparent !important;
}

.navbar {
position: relative;
margin-bottom: 0px;
min-height: 0px;
border: 0px solid transparent;
text-transform: uppercase;
}

.nav-tabs.nav-justified>li>a {
border: none;
border-radius: none;
}

.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:focus, .nav-tabs.nav-justified>.active>a:hover {
  border-bottom-color: #fff !important;
  border: 0px solid #ddd !important;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  border-bottom-color: transparent !important;
}

.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:focus, .nav-tabs.nav-justified>.active>a:hover {
  border-bottom-color: #fff !important;
  border: 0px solid #ddd !important;
}
.nav-tabs.nav-justified>li>a {
  border: 0px solid white !important;
  border-bottom: 0px solid #ddd !important;
  border-radius: 0px !important;
  color: white;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

/*  .menu-box ul {/*display: table; max-width: 1010px; margin: 0px auto; padding: 0px; }

.menu-box ul li {display: table-cell; float: left; padding: 5px 10px; text-align: center; font-family: 'Quicksand', sans-serif; font-size: 1em; font-weight: 400; text-transform: uppercase;}

.menu-box ul li a {color: white;}

.menu-box ul li a:hover {text-decoration: none;}  */

.nav-menu-modal {

position: absolute; top: 183px; min-height: 300px; background-color: white; background-color: rgba(250,250,250, .95); z-index: 300; width: 100%; box-shadow: 1px 1px 5px gray; display: none;
}

.menu-modal-inner {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.menu-modal-inner .menu-item {

margin-right: 5px;
}

.menu-item-has-children > .menu-item {
/* padding: 5px; */
color: black;
/*background-color: #3f9645;*/
width: 48%;
float: none;
}

.menu-item a {
  font-size: 1.1em !important;
}

.sub-menu li a {
  font-size: 1em !important;
}







ul.menu-modal-main {

/*min-width: 100%;
max-width: 860px; */
min-height: 100%;
display: table;
padding: 0px;
margin: 0px auto;
height: 47px;
}

ul.menu-modal-main li {

display: table-cell;
vertical-align: middle;
text-align: center;
padding: 0px 20px;

}

ul.menu-modal-main li:hover {
background-color: wheat;
}

ul.menu-modal-main li:hover a {
color: black;
text-decoration: none;
display: block;
padding: auto;

}

ul.menu-modal-main li a {

display: block;

}

.menu-modal-inner li.menu-item {
float: none;
/* padding: 10px; */
color: white;
/* background-color: #3f9645; */
width: 48%;
}

.menu-modal-inner li.menu-item-has-children {
float: left !important;
/* padding: 10px; */
color: white;
/* background-color: #3f9645; */
width: 48%;
}

.menu-modal-inner li.menu-item-has-children li.menu-item  {
width: 100%;
list-style-type: none;
}

li.menu-item-has-children {
float: left;
/* padding: 10px; */
color: black;
/* background-color: #3f9645; */
width: 90%;
list-style-type: none;
background-color: transparent !important;
display: inline-block;
top: 0px;
}

.menu-modal-inner .menu-item a {
color: white;
background-color: #3f9645;
background-position: top center;
background-size: cover;
width: 100%;
padding: 8px 10px;
display: inline-block;
border: 1px solid white;
transition: all .25s ease-in-out;
-moz-transition: all .25s ease-in-out;
-webkit-transition: all .25s ease-in-out;
}

.menu-modal-inner .menu-item a:hover {
background-color: gray;
background-image: none;
text-decoration: none;
}

.menu-modal-inner .menu-item-has-children .sub-menu a {
color: black;
width: 100%;
padding: 5px 0px;
display: block;
background-color: transparent !important;
background-image: none;
  border: 0px solid white;
}

.menu-item-has-children a {
color: black;
width: 100%;
padding: 15px;
display: block;
}

.menu-item-has-children .sub-menu {

margin: 5px 0px 10px;

}

ul.widget-area {
padding: 5px;
margin: 0px;
list-style-type: none !important;
}

ul.widget-area li ul {

  list-style-type: none !important;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

ul.widget-area li ul li ul {
padding: 10px 20px;
  list-style-type: none !important;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 80%;
  margin-left: 15%;
  margin-right: 5%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 0px;
  background-color: white;
}

ul.widget-area li ul a {
  background-color: #3f9645;
  color: white;
  padding: 5px 10px;
  width: 100%;
  border-radius: 3px;
  display: block;
  font-size: 1.2em;
}

ul.widget-area li ul a:hover {
  background-color: gray;
  text-decoration: none;
}

.container-outer {
display: block;
padding-right: 0px;
padding-left: 0px;
margin-right: 0px;
margin-left: 0px;
width: 100%;
max-height: 100vh;
}


#homepage-main-box {

 margin: 30px auto 30px;
 z-index: auto;

}

.page-box {

margin: 0px auto ;
/*border-radius: 2px;*/
background-color: white;
/*box-shadow: 0px 0px 1px gray;*/
padding: 20px;
line-height: 1.4;

}

.gallery-box {

margin: 0px auto 45px;
/*border-radius: 2px;*/
/*background-color: white;*/
/*box-shadow: 0px 0px 1px gray;*/
padding: 20px;
line-height: 1.4;

}

.page-box-subpage {

margin-top: 15px;
margin-bottom: 15px;
border-radius: 5px;
background-color: white;
box-shadow: 0px 0px 1px gray;
padding: 20px;

}

.subpage-info-box {

background-color: #aac99c;
min-width: 100%;
height: auto;
padding: 10px 20px;
margin: 0px -15px;
color: black;
border-radius: 5px;
border: 1px solid #76a362;
line-height: 1.4;
}

.subpage-info-box a { color: black; text-decoration: underline;}

a.info-box-button {
  display: inline-block;
  float: right;
  width: auto;
  background-color: #f6861f;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 0px 1px gray;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  line-height: 1;
}

a.info-box-button:hover {
  text-decoration: none;
  background-color: #3f9645;
  color: white;
  box-shadow: 0px 0px 1px black;
}

.home-box-main-option {
background-color: #4e567a;
height: 300px;
  position: relative;
display: block;
}

.scroll-box {
  max-height: 230px;
  max-width: 80%;
  margin: 0px auto;
  display: block;

  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroll-box:hover {
overflow-y: scroll;
overflow-x: hidden;
-webkit-overflow-scrolling: touch;
}

.scroll-box::-webkit-scrollbar {
width: 3px;
height: 3px;
background-color: #666666;
}

.scroll-box::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
background-color: black;
}

.scroll-box::-webkit-scrollbar-thumb {
background-color: #F5F5F5;
border: 0px solid #6C6C6C;
}

.home-box-main-scroll {
color: white;
}

.home-box-main-header {
  display: block;
  padding: 15px 20px;
  /* width: 100%; */
  background-color: #4e567a;
  border-bottom: 1px solid lightgray;
  text-align: center;
  margin: 0px 10px;
}

.home-calendar-box {

color: white;

}

.home-calendar-title {

color: white; font-size: 1.4em;
font-weight: bold;
}

.home-calendar-time {

font-size: 110%;
/* border-right: 1px solid #c04e37; */
}


.home-calendar-description {

border-left: 1px solid lightgray;
font-size: 95%;
}

a.calendar-button {

display: block;
background-color: gray;
color: white;
padding: 10px;
width: 100%;

}

a:hover.calendar-button {

background-color: lightgray;
text-decoration: none;
color: black;

}


.homeOption {
background-color: white;
height: 260px;
padding: 0px 20px;
line-height: 1.4;
transition: opacity .25s ease-in-out;
-moz-transition: opacity .25s ease-in-out;
-webkit-transition: opacity .25s ease-in-out;
}

.homeOption.active {
display: inline;
opacity: 1;
transition-delay:0s;
}

.homeOption.inactive {
display: none;
opacity: 0;
}

.hero-image-background {

    min-height: 500px;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 0px;

    @include breakpoint($xs){
      min-height: 200px;
    }

    @include breakpoint($sm){
      min-height: 60vh;
    }
    @include breakpoint($md){
      min-height: 70vh;
    }
}

.hero-image-title-bar {
display: block; position: absolute; background-color: gray; background-color: rgba(0,0,0, .4); height: auto; width: 100%; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); padding: 20px;
}

.hero-images-title {
text-align: center; color: white; font-size: 1.6em; max-width: 750px; margin: 5px auto;
}

.hero-images-subtitle {
text-align: center;  color: white; font-size: 1.1em; max-width: 750px; margin: 5px auto;
}

nav.navTable {
display: table;
width: 100%;
}

ul.ulRow {
display:table-row;
width: 100%;
margin: 0;
padding: 0;
}

li.liCell {
display: table-cell;
vertical-align: middle;
text-align:center;
background-color: #ebebea;
border: 1px solid #dedddd;
height: 40px;
width: auto;
font-weight: bold;
}

.liCell.active {
position: relative;
color: white;
background-color: #3f9645;
border: none;

}

.liCell.active:after, .liCell.active:before {
top: 100%;
left: 50%;
border: solid transparent;
content: " ";
height: 0;
width: 0;
position: absolute;
pointer-events: none;
}

.liCell.active:after {
border-color: rgba(63, 160, 69, 0);
border-top-color: #3f9645;
border-width: 5px;
margin-left: -5px;
z-index: 1000;
}




a.subPageBox {
background-color: white;
display: inline-block;
height: auto;
margin: 0px auto 5px;
border-radius: 5px;
box-shadow: 0px 0px 1px #4e567a;
text-decoration: none;
width: 100%;
min-height: 360px;
overflow: hidden;
opacity: .8;
transition: opacity .25s ease-in-out;
-moz-transition: opacity .25s ease-in-out;
-webkit-transition: opacity .25s ease-in-out;
}

a.subPageBox:hover {

text-decoration: none;
opacity: 1;
box-shadow: 0px 0px 2px gray;
}

.subPageImage {
width: 100%;
height: auto;
top-right-border-radius: 5px;
top-right-border-radius: 5px;
}

.subPageTitle {
padding: 10px;
margin: 0px;
color: black;
text-align: center;
}

.subPageText {
padding: 10px;
margin: 0px;
color: black;
}

.subPageBoxbutton {
  display: block;
  float: right;
  background-color: gray;
  color: white;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  padding: 5px;
  margin: -10px 10px 15px;
}




a.SideBarsubPageBox {
background-color: white;
display: inline-block;
height: auto;
margin: 0px auto 5px;
border-radius: 5px;
box-shadow: 0px 0px 1px gray;
text-decoration: none;
width: 100%;
overflow: hidden;
opacity: .8;
height: 60px;
transition: all .25s ease-in-out;
-moz-transition: all .25s ease-in-out;
-webkit-transition: all .25s ease-in-out;
}

a.SideBarsubPageBox:hover {

text-decoration: none;
opacity: 1;
height: 180px;
box-shadow: 0px 0px 2px gray;
}


a.SideBarsubCatBox {
background-color: white;
display: inline-block;
height: auto;
margin: 0px auto 5px;
border-radius: 5px;
box-shadow: 0px 0px 1px gray;
text-decoration: none;
width: 100%;
overflow: hidden;
opacity: .8;
/*height: 60px;*/
padding: 10px;
font-size:  1.1em;
transition: all .25s ease-in-out;
-moz-transition: all .25s ease-in-out;
-webkit-transition: all .25s ease-in-out;
}

a.SideBarsubCatBox:hover {

text-decoration: none;
opacity: 1;
box-shadow: 0px 0px 2px gray;
background-color: #3F9645;
color: white;
}


a.PostListBox {
background-color: white;
display: inline-block;
height: auto;
margin: 0px auto 5px;
border-radius: 5px;
box-shadow: 0px 0px 1px gray;
text-decoration: none;
width: 100%;
min-height: 350px;
overflow: hidden;
opacity: .8;
transition: opacity .25s ease-in-out;
-moz-transition: opacity .25s ease-in-out;
-webkit-transition: opacity .25s ease-in-out;
}

a.PostListBox:hover {

text-decoration: none;
opacity: 1;
box-shadow: 0px 0px 2px gray;
}


.CatPostListBox {
background-color: white;
display: inline-block;
height: auto;
margin: 0px auto 25px;
border-radius: 5px;
box-shadow: 0px 0px 1px gray;
text-decoration: none;
width: 100%;
overflow: hidden;
opacity: .8;
transition: opacity .25s ease-in-out;
-moz-transition: opacity .25s ease-in-out;
-webkit-transition: opacity .25s ease-in-out;
}

.CatPostListBox a {text-decoration: none;}

.CatPostListBox:hover {

text-decoration: none;
opacity: 1;
box-shadow: 0px 0px 2px gray;
}


.PostListImage {
width: 100%;
height: auto;
top-left-border-radius: 5px;
top-right-border-radius: 5px;
}


.PostListTitle {
font-size: 1.3em;
padding: 10px;
margin: 0px;
color: black;
}

.PostListTimeStamp {
padding: 10px;
margin: 0px;
color: gray;
}

.PostListText {
padding: 10px;
margin: 0px;
color: black;
}

a.PostListBoxbutton {
  display: inline;
  float: right;
  background-color: gray;
  color: white;
  border-radius: 5px;
  border: none;
  /* text-transform: uppercase; */
  padding: 3px 5px;
  /* margin: -15px 10px 15px; */
  z-index: 5;
  /* position: relative; */
  line-height: 1.5em;
   transition: opacity .25s ease-in-out;
-moz-transition: opacity .25s ease-in-out;
-webkit-transition: opacity .25s ease-in-out;
}

a.PostListBoxbutton:hover {
  background-color: #f6861f;
  color: white;
  text-decoration: none;
}


a.BlogListBox {
background-color: white;
display: inline-block;
height: auto;
margin: 0px auto 5px;
border-radius: 5px;
box-shadow: 0px 0px 1px gray;
text-decoration: none;
width: 100%;

overflow: hidden;
opacity: .8;
transition: opacity .25s ease-in-out;
-moz-transition: opacity .25s ease-in-out;
-webkit-transition: opacity .25s ease-in-out;
}

a.BlogListBox:hover {

text-decoration: none;
opacity: 1;
box-shadow: 0px 0px 2px gray;
}

.BlogListImage {
width: 100%;
max-width: 350px !important;
height: auto;
top-left-border-radius: 5px;
float: left;
margin-right: 10px;
}

.BlogListTitle {
font-size: 1.3em;
padding: 10px;
margin: 10px 0px;
color: black;
}



.dark-header {
  color: white;
  font-size: 3em;
  background-color: #4f4f4f;
  background-color: rgba(0,0,0, .8);
  margin-top: 40px;
}

.profile-text {font-size: .8em; padding: 10px ;}

.image-contain img {max-width: 100%; max-height: 100%; height: auto; width: auto;}

.form-title {font-size: 1.3em; font-weight: bold;}

input {max-width: 100%;}

input.form-control {margin: 3px 3px; border: 1px solid gray !important; max-width: 95%; border-radius: 5px; color: white; background-color: #4e567a; padding: 0px 5px;}

textarea {margin: 10px 5px; /*border: 2px solid black !important; */ width: 100%;}

input.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: white;
  font-weight: normal;
}
input.form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color: white;
 font-weight: normal;
 opacity:  1;
}
input.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
 color: white;
 font-weight: normal;
 opacity:  1;
}
input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: white;
 font-weight: normal;
}

.login-form {font-size: .7em; font-weight: bold; color: gray;}

.login-form input {height: 25px;}

.contact-box {font-size: 1.1em; font-weight: bold; color: gray;}

.contact-box a:hover {
  text-decoration: none;
}

.vertical-align-row {
position: relative;
}

.vertical-align-box {
display: block;
position: absolute;
top: 50%;
right: 0;
-webkit-transform: translateY(-50%) !important;
transform: translateY(-50%);
}



.row-table {
display: table;
height: 100%;
min-width: 100%;
}

.row-cell {
display: table-cell;
vertical-align: middle;
float: none;
height: 100%;
}

form {margin: 0px;}



.nav-previous a, .nav-next a {color: white !important; padding: 10px; margin: 10px; background-color: #f6861f;}


.footer-box {
  background-image: url('../images/footer-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center bottom;
  width: 100%;
  position: relative;
  display: block;


}

.footer-inner {
  display: block;;
  /*position: absolute;*/
  padding: 20px;
  bottom: 0;
  height: 50%;
  background-color: black;
  background-color: rgba(0,0,0, .4);
  color: white;
  width: 100%;
  min-height: 350px;
}

.footer-inner .container {

margin-top: 120px;
margin-bottom: 80px;

}





.footer-inner h2 {

color: white;

}

.footer-button {float: left; background-color: #4e567a; padding: 10px; width: auto; border: 0px; margin-bottom: 10px;}

.footer-button a {color: white; text-decoration: none;}

.social-buttons {float: left; margin: 0px 10px;}

.social-buttons a {

font-size: 2em;
color: white;
margin: 0px 5px;

}


@media(max-width:767px){

.footer-inner .container .col-xs-12 {

  background-color: black;
  background-color: rgba(0,0,0, .4);

}

.menu-menu-one-container {display: none;}

.profile-text {font-size: .8em;}

.mobile-column-center {margin-left: auto; margin-right: auto;}

.mobile-center {text-align:center; font-size: 120%; }

.dark-header {display: none;}

.hideMobile {display: none !important;}

.contact-box {
  font-size: 1em;
  font-weight: normal;
  color: gray;
}

.scroll-box {
overflow-y: scroll;
overflow-x: hidden;
-webkit-overflow-scrolling: touch;
}

.homeOption {
  background-color: white;
  height: auto;
  padding: 10px 20px;
}

.homeOption.inactive {
display: inline;
opacity: 1;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

.nav-tabs.nav-justified>li>a {
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
}

.nav-tabs.nav-justified>li>a {
  border: 1px solid white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}


.open>.dropdown-menu {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.open>.dropdown-menu:after {
  clear: both;
}

.dropdown-menu {
  position: static;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  padding: 5px 0;
  margin: -5px auto 20px;
  font-size: 1em;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-color: rgba(250,250,250, .7);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.nav-tabs>li {
  float: left !important;
  width: 100%;
  margin-bottom: -1px;
}

.navbar-toggle {
  border: 1px solid white;
}

.nav-menu-modal {

display: none;
}

.nav-menu-modal-mobile {

display: none; position: static !important; min-height: 300px; background-color: white; background-color: rgba(250,250,250, .9); z-index: 300; width: 100%; box-shadow: 1px 1px 5px gray;
}

.menu-main-menu-container {

display: none;

}

.menu-modal-inner li.menu-item, .menu-modal-inner li.menu-item-has-children {
  float: left !important;
  /* padding: 10px; */
  color: white;
  /* background-color: #3f9645; */
  /* width: 48%; */

  width: 100%;
}


.menu-item-has-children .sub-menu {
  margin: 5px 0px 10px;
  display: inline-block;
}


.home-calendar-box {

color: white;
font-size: 100%;

}


.hero-image-title-bar {

font-size: 70%;
bottom: 0px;
position: absolute;;
top: none;
-ms-transform: translateY(0%);
-webkit-transform: translateY(0%);
transform: translateY(0%);


}


}


@media(min-width:768px){

.hideDesktop {display: none;}

.nav-menu-modal-mobile { display: none; }



}

@media(min-width:768px) and (max-width: 1024px) {

.hero-image-background {
min-height: 250px;
background: no-repeat center center;
background-attachment: initial;
-moz-background-size: cover;
-webkit-background-size: cover;
background-size: cover;
background-position: fixed;
border: 0px;
margin: 0px;
}

}



@media all and (min-width: 999px) {



}


@media(min-width:1200px){



}





/*                  PORTFOLIO TEMPLATE PAGE SPECIFIC                   */


@media all and (min-width: 999px) {

.portfolio-display.active {
    opacity: 1 !important;
    z-index: 2 !important;
    display: block !important;
    color: white;
  }


.portfolio-display, .portfolio-slider, .portfolio-slide a {
transition: font-size 0.9s, /*height 0.9s,*/ opacity 0.9s;
transition-property: font-size, /*height,*/ opacity;
transition-duration: 0.9s, /*0.9s,*/ 0.9s;
transition-timing-function: initial, /*initial,*/ initial;
transition-delay: initial, /*initial,*/ initial;
-webkit-transition: font-size 0.9s, /*height 0.9s,*/ opacity 0.9s;
-moz-transition: font-size 0.9s, /*height 0.9s,*/ opacity 0.9s;
}



.portfolio-display {display:none; position: relative; left: 0; top: 0; height: 100%; width: 100%; border: 0px red solid; opacity: 0; background-size: contain !important; z-index: 50;}

.portfolio-title {margin-top: 140px; margin-left:20px; background-color: black; background-color: rgba(0,0,0,0.7);padding: 5px; width: auto; font-size: 2em;}

.portfolio-description {
position: absolute;
padding: 5px;
width: 225px;
top: 140px;
right: 20px;
background-color: black;
background: rgba(0,0,0,.7);
border-radius: 5px;
border: solid 1px black;
border-bottom: solid 1px black;
box-shadow: 0px 0px 10px black;
max-height: 350px;
overflow:hidden;
}

.portfolio-description p {
margin-right: 4px;
margin-top: 0px;
margin-bottom: 0px;
}


.portfolio-description:hover {
overflow-y: scroll;
overflow-x: hidden;
-webkit-overflow-scrolling: touch;
}

.portfolio-description::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
background-color: black;
}

.portfolio-description::-webkit-scrollbar {
width: 3px;
height: 3px;
background-color: #666666;
}

.portfolio-description::-webkit-scrollbar-thumb {
background-color: #F5F5F5;
border: 0px solid #6C6C6C;
}

.portfolio-description-button {
position: absolute;
top: 110px;
right: 10px;
z-index: 20;
font-size: 3em;
display: none;
}

i.fa.fa-info {

padding: 0px;
margin: 0px;

}

.full-screen-button {
position: absolute;
top: 150px;
right: 10px;
z-index: 5000;
font-size: 3em;
display: block;
background: rgba(0,0,0,.7);
border-radius: 5px;
padding: 10px;
cursor: pointer;
text-align: center;
color: white;
}

.fa-times-circle, .fa-arrows-alt {

padding: 0px;
margin: 0px auto;

}

.play-pause-button {
position: fixed;
bottom: 130px;
right: 10px;
z-index: 5000;
font-size: 3em;
display: block;
background: rgba(0,0,0,.7);
border-radius: 5px;
padding: 10px;
cursor: pointer;
color: white;
}

.fa-pause, .fa-play {

padding: 0px;
margin: 0px auto;

}

.view-more {
position: absolute; padding: 15px; width: 225px; bottom: 170px; right: 20px; background: rgba(0,0,0,.7); border-radius: 20px; text-align: center; font-weight: bold; text-transform: uppercase; text-decoration: none; }

.view-more a {text-decoration: none; color: white; }

.view-more a:hover {text-decoration: none; color: white; }

.portfolio-slider-box {display:block; position: fixed; left: 0; bottom: 0; height: 125px; width: 100%; max-width: 100%; border: 0px red solid; z-index: 5; padding: 0px; margin: 0px; background: black; z-index: 51; overflow-x: hidden; overflow-y: hidden; white-space: nowrap;  }


.portfolio-slider {
position: relative;
margin: 0px;
padding: 0px;
white-space: nowrap;
height: 125px;
width: auto;
overflow:hidden;
}

.portfolio-slider:hover {
overflow-y: hidden;
overflow-x: scroll;
-webkit-overflow-scrolling: touch;
}

.portfolio-slider::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
background-color: black;
}

.portfolio-slider::-webkit-scrollbar {
width: 3px;
height: 3px;
background-color: #666666;
}

.portfolio-slider::-webkit-scrollbar-thumb {
background-color: #F5F5F5;
border: 0px solid #6C6C6C;
}

.portfolio-slide {display: inline-block; width: 250px; height: 125px; border: 0px red solid; z-index: 5; margin: 0px; padding: 0px; background-color: black;}

ul.portfolio-slide {margin: 0px; padding: 0px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;}

.portfolio-slide a {display: inline-block; width: 100%; height: 100%; background-size: cover !important; opacity: .5; position:relative; }

.portfolio-slide a:hover {opacity: 1; }

.slide-title {display: none;}

#prev_nav{position: fixed; left: 5px; bottom: 40px; font-size:3em; color: white;cursor: pointer;z-index: 3000; background: gray; background: rgba(111, 111, 111, 0.7);  border-radius: 20px; width: 40px; height: 40px; text-align:center; opacity: .7; cursor: pointer;}

#next_nav{position: fixed; right: 5px; bottom: 40px; font-size:3em; color: white;cursor: pointer;z-index: 3000; background: gray; background: rgba(111, 111, 111, 0.7);  border-radius: 20px; width: 40px; height: 40px; text-align:center; opacity: .7; cursor: pointer;}

#prev_nav:hover { opacity: 1; cursor: pointer;  }

#next_nav:hover { opacity: 1; cursor: pointer; }

}



@media all and (max-width: 999px) {



.portfolio-display.active {
opacity: 1 !important;
z-index: 2 !important;
display: block !important;
}

.portfolio-display, .portfolio-slider, .portfolio-slide a {
transition: font-size 0.9s, height 0.9s, opacity 0.9s;
transition-property: font-size, height, opacity;
transition-duration: 0.9s, 0.9s, 0.9s;
transition-timing-function: initial, initial, initial;
transition-delay: initial, initial, initial;
-webkit-transition: font-size 0.9s, height 0.9s, opacity 0.9s;
-moz-transition: font-size 0.9s, height 0.9s, opacity 0.9s;
}

.portfolio-display {
display: none;
position: relative;
left: 0;
top: 0;
height: 100%;
width: 100%;
border: 0px red solid;
opacity: 0;
background-color: black;
background-size: contain !important;
z-index: 50;}

.portfolio-title {margin-top: 140px; margin-left:20px; background-color: black; background-color: rgba(0,0,0,0.7);padding: 5px; width: auto; font-size: 2em;}

.portfolio-description {
display: none; position: absolute; max-height: 225px; padding: 10px; width: 185px; top: 105px; right: 10px; background: rgba(0,0,0,.7); overflow:scroll; border-radius: 10px; font-size: 80%; }

.portfolio-description p {
margin-top: 0px;
margin-bottom: 0px;
}

.portfolio-description-button {
position: absolute;
top: 70px;
right: 5px;
z-index: 20;
font-size: 1.6em;
padding: 3px;
margin: 0px;

height: 2em;
width: 2em;
text-align: left;

}

i.fa.fa-info {
text-align: center;
padding: 0px;
margin: 0px;
/*box-shadow: 0px 0px 5px black;*/
border-radius: 1.2em;
height: 1em;
width: 1em;
background: rgba(0,0,0,.7);

}

.view-more {
position: absolute; padding: 15px; width: 150px; bottom: 130px; right: 10px; background: rgba(0,0,0,.7); border-radius: 20px; text-align: center; font-weight: bold; text-transform: uppercase; text-decoration: none; font-size: 80%; color: white;}

.view-more a {text-decoration: none; color: white; }
.view-more a:visited {color: white; }

.view-more a:hover {text-decoration: none; }

.portfolio-slider-box {display:block; position: fixed; left: 0; bottom: 0; height: 100px; width: 100%; max-width: 100%; border: 0px red solid; z-index: 5; padding: 0px; margin: 0px; background: black; z-index: 51; overflow-x: hidden; overflow-y: hidden; white-space: nowrap;  }

.portfolio-slider {position: relative; margin: 0px; padding: 0px; white-space: nowrap; height: 100px; overflow-y: hidden; overflow-x: scroll; -webkit-overflow-scrolling: touch;}

.portfolio-slide {display: inline-block; width: 150px; height: 100px; border: 0px red solid; z-index: 5; margin: 0px; padding: 0px; background-color: black;}

.portfolio-slide a {display: inline-block; width: 100%; height: 100%; background-size: cover !important; opacity: .5; position:relative; }

.portfolio-slide a span {font-size: 10px; overflow: hidden;}

.portfolio-slide a:hover {opacity: 1; }

.slide-title {display: none;}

#prev_nav{display: none; position: fixed; left: 5px; bottom: 30px; font-size:3em; color: white; cursor: pointer; z-index: 3000; background: gray; background: rgba(111, 111, 111, 0.7);  border-radius: 20px; width: 40px; height: 40px; text-align:center; opacity: .7; cursor: pointer;}

#next_nav{display: none; position: fixed; right: 5px; bottom: 30px; font-size:3em; color: white;cursor: pointer;z-index: 3000; background: gray; background: rgba(111, 111, 111, 0.7);  border-radius: 20px; width: 40px; height: 40px; text-align:center; opacity: .7; cursor: pointer;}

#prev_nav:hover { opacity: 1; box-shadow: 0px 0px 10px black;}

#next_nav:hover { opacity: 1; box-shadow: 0px 0px 10px black;}

.full-screen-button {
position: fixed;
top: 150px;
right: 5px;
z-index: 5000;
font-size: 1em;
display: block;
background: rgba(0,0,0,.7);
border-radius: 5px;
padding: 10px;
cursor: pointer;
text-align: center;
color: white;
}

.fa-pause, .fa-play {

padding: 0px;
margin: 0px auto;

}


.play-pause-button {
position: fixed;
bottom: 120px;
right: 5px;
z-index: 5000;
font-size: 1em;
display: block;
background: rgba(0,0,0,.7);
border-radius: 5px;
padding: 10px;
cursor: pointer;
text-align: center;
color: white;
}

.fa-pause, .fa-play {

padding: 0px;
margin: 0px auto;

}

}

@media all and (max-width: 999px) and (orientation:landscape) {

#topheader { /*display: none; height: 0px; */}  /* BREAKS SOMETIMES - TAKE NOTE */

.portfolio-description {
display: none; position: absolute; max-height: 150px; padding: 10px; width: 185px; top: 105px; right: 10px; background: rgba(0,0,0,.7); overflow:scroll; border-radius: 10px; font-size: 80%; }

.portfolio-slider-box {display: none; height: 0px;}

/*.header {height: 20px;}*/

.play-pause-button {
position: fixed;
bottom: 20px;
right: 5px;
z-index: 5000;
font-size: 1em;
display: block;
background: rgba(0,0,0,.7);
border-radius: 5px;
padding: 10px;
cursor: pointer;
text-align: center;
}

}
